import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker'; // Ensure this is imported
import 'react-datepicker/dist/react-datepicker.css'; // Import CSS
import '../AdminSystem/NewUser.css';
import Sidebar from './SideBar';

const SystemAdminForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    companyName: '',
    address: '',
    city: 'Surat',  // Set default city to Surat
    state: 'Gujarat',  // Set default state to Gujarat
    contactPersonName: '',
    contactPersonNumber: '',
    softwareCategory: '',
    defaultDB: '',
    dbUsername: '',
    dbPassword: '',
    staticIP: '',
    port: '',
    remark: '',
    validFrom: new Date(),
    validTo: new Date(),
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://dsapp.shanti-pos.com:8080/user/system-admin', formData);
      setFormData({
        email: '',
        password: '',
        companyName: '',
        address: '',
        city: '',
        state: '',
        contactPersonName: '',
        contactPersonNumber: '',
        softwareCategory: '',
        defaultDB: '',
        dbUsername: '',
        dbPassword: '',
        staticIP: '',
        port: '',
        remark: '',
        validFrom: new Date(),
        validTo: new Date(),
      });
      alert("System Admin created successfully!"); // Success message
    } catch (error) {
      console.error('Error creating system admin:', error);
      // Check if the error response contains a message and display it
      const errorMessage = error.response?.data?.message || "There was an error. Please try again later."; 
      alert(errorMessage); // Show the backend error message or fallback message
    }
  };

  return (
    <div className="sys-admin-form-container">
      <Sidebar/>
      <h2>Create New User</h2>
      <form onSubmit={handleSubmit} className="sys-admin-form">
        {/* Email, Password and Company Name Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Address, City, State Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={formData.state}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Contact Person Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="contactPersonName">Contact Person Name</label>
            <input
              type="text"
              id="contactPersonName"
              name="contactPersonName"
              value={formData.contactPersonName}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="contactPersonNumber">Contact Person Number</label>
            <input
              type="text"
              id="contactPersonNumber"
              name="contactPersonNumber"
              value={formData.contactPersonNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-field">
            <label htmlFor="softwareCategory">Software Category</label>
            <select
              id="softwareCategory"
              name="softwareCategory"
              value={formData.softwareCategory}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Garment R">Garment R</option>
              <option value="Garment W">Garment W</option>
              <option value="SuperStore">SuperStore</option>
              <option value="Restaurant">Restaurant</option>
              <option value="WeighBridge">WeighBridge</option>
              <option value="Jari">Jari</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        {/* Default Database, DB Username, and DB Password Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="defaultDB">Default Database</label>
            <input
              type="text"
              id="defaultDB"
              name="defaultDB"
              value={formData.defaultDB}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbUsername">Database Username</label>
            <input
              type="text"
              id="dbUsername"
              name="dbUsername"
              value={formData.dbUsername}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbPassword">Database Password</label>
            <input
              type="password"
              id="dbPassword"
              name="dbPassword"
              value={formData.dbPassword}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Static IP and Port Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="staticIP">Static IP</label>
            <input
              type="text"
              id="staticIP"
              name="staticIP"
              value={formData.staticIP}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="port">Port</label>
            <input
              type="number"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              required
            />
          </div>


        </div>


        {/* Valid From and Valid To Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="validFrom">Valid From</label><br />
            <DatePicker
              selected={formData.validFrom}
              onChange={(date) => handleDateChange(date, 'validFrom')}
              dateFormat="dd/MM/yyyy"
              required
              className="form-date-picker"
              showMonthDropdown // Enables the month dropdown
              showYearDropdown // Enables the year dropdown
              dropdownMode="select" // Use dropdown mode for both month and year
              scrollableMonthYearDropdown // Makes the month and year dropdown scrollable
            />


          </div>
          <div className="form-field">
            <label htmlFor="validTo">Valid To</label><br />
            <DatePicker
              selected={formData.validTo}
              onChange={(date) => handleDateChange(date, 'validTo')}
              dateFormat="dd/MM/yyyy"
              required
              className="form-date-picker"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              scrollableMonthYearDropdown
            />

          </div>
        </div>


        {/* Remark field will span the full width */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="remark">Remark</label>
            <input
              type="text"
              id="remark"
              name="remark"
              value={formData.remark}
              onChange={handleChange}  // Add this onChange handler
              className="full-width"
            />
          </div>
        </div>


        {/* Submit Button */}
        <button type="submit" className="sys-admin-submit-btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default SystemAdminForm;
