import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './SideBar.js';
import Card from '../Dashboard/Card1.js';
import '../css/DashboardPage.css';
import axios from 'axios';
import { Pie, Bar } from 'react-chartjs-2';
import SalesChart from './SalesChart.js';
import { Chart, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import PurchaseChart from './PurchaseChart.js';
import PaymentChart from './PaymentChart.js';
import ReceiptChart from './RecepitChart.js';
import { LineController, LineElement, Title, Filler } from 'chart.js';
Chart.register(LineController, LineElement, Title, Filler);
Chart.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const DashboardPage = () => {
  const location = useLocation();
  const [companyName, setCompanyName] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [totalPayables, setTotalPayables] = useState(0);
  const [currentTotalCash, setCurrentTotalCash] = useState(0);
  const [currentTotalCredit, setCurrentTotalCredit] = useState(0);
  const [currentLocalAmount, setCurrentLocalAmount] = useState(0);
  const [currentOutsideAmount, setCurrentOutsideAmount] = useState(0);
  const [purchaseData, setPurchaseData] = useState([]);
  const [currentCard, setCurrentCard] = useState(0);  // Fix: Default value to 0 instead of empty array
  const [currentWallet, setCurrentWallet] = useState(0);  // Fix: Default value to 0 instead of empty array
  const [currentCreditNote, setCurrentCreditNote] = useState(0);  // Fix: Default value to 0 instead of empty array
  const [currentWholesaleAmount, setCurrentWholesaleAmount] = useState(0);
  const [currentTaxFreeAmount, setCurrentTaxFreeAmount] = useState(0);
  const [currentJobworkAmount, setCurrentJobworkAmount] = useState(0);
  const [currentBranchTransferAmount, setCurrentBranchTransferAmount] = useState(0);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlCompanyName = queryParams.get('companyName');
    const urlFromDate = queryParams.get('fromDate');
    const urlToDate = queryParams.get('toDate');

    if (urlCompanyName && urlFromDate && urlToDate) {
      localStorage.setItem('companyName', urlCompanyName);
      localStorage.setItem('fromDate', urlFromDate);
      localStorage.setItem('toDate', urlToDate);
      setCompanyName(urlCompanyName);
      setFromDate(urlFromDate);
      setToDate(urlToDate);
    } else {
      setCompanyName(localStorage.getItem('companyName') || '');
      setFromDate(localStorage.getItem('fromDate') || '');
      setToDate(localStorage.getItem('toDate') || '');
    }
  }, [location.search]);

  const fetchOutstandingData = useCallback(async (endpoint, setTotal) => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`http://dsapp.shanti-pos.com:8080/api/${endpoint}`, {
        params: { date: today, CompCode: compCode, dueDays: 0 },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (Array.isArray(response.data)) {
        const total = response.data.reduce((acc, item) => acc + (parseFloat(item.field11) || 0), 0);
        setTotal(total.toFixed(2));
      } else {
        console.error('Data is not an array:', response.data);
        setTotal(0);
      }
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  }, []);

  const fetchPaymentData = useCallback(async () => {
    try {
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`http://dsapp.shanti-pos.com:8080/api/get-summary`, {
        params: { CompCode: compCode },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const cashTotal = response.data
          .filter(item => item.PAYMENT === 'CASH')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const creditTotal = response.data
          .filter(item => item.PAYMENT === 'CREDIT')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const cardTotal = response.data
          .filter(item => item.PAYMENT === 'CARD')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const walletTotal = response.data
          .filter(item => item.PAYMENT === 'WALLET')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        const creditNoteTotal = response.data
          .filter(item => item.PAYMENT === 'CREDIT NOTE')
          .reduce((acc, item) => acc + (parseFloat(item.Amount) || 0), 0);

        setCurrentTotalCash(cashTotal);
        setCurrentTotalCredit(creditTotal);
        setCurrentCard(cardTotal);
        setCurrentWallet(walletTotal);
        setCurrentCreditNote(creditNoteTotal);
      } else {
        console.warn('No payment data received or data is not in expected format');
        setCurrentTotalCash(0);
        setCurrentTotalCredit(0);
        setCurrentCard(0);
        setCurrentWallet(0);
        setCurrentCreditNote(0);
      }
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  }, []);

  const fetchPurchaseSummary = useCallback(async () => {
    try {
      const selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
      const compCode = selectedCompany ? selectedCompany.CompCode : '';

      const response = await axios.get(`http://dsapp.shanti-pos.com:8080/api/purchase-summary`, {
        params: { CompCode: compCode },
        headers: {
          'UserId': localStorage.getItem('userId'),
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        setPurchaseData(response.data);
      } else {
        console.warn('No purchase data received or data is not in expected format');
        setPurchaseData([]);
      }
    } catch (error) {
      console.error('Error fetching purchase summary:', error);
    }
  }, []);

  useEffect(() => {
    if (companyName && fromDate && toDate) {
      fetchOutstandingData('outstanding-receivables', setTotalReceivables);
      fetchOutstandingData('outstanding-payables', setTotalPayables);
      fetchPaymentData();
      fetchPurchaseSummary();
    }
  }, [companyName, fromDate, toDate, fetchPaymentData, fetchOutstandingData, fetchPurchaseSummary]);

  useEffect(() => {
    if (Array.isArray(purchaseData) && purchaseData.length > 0) {
      const localAmount = purchaseData.find(item => item.sptype === 'LOCAL')?.Amount || 0;
      const outsideAmount = purchaseData.find(item => item.sptype === 'OUTSIDE')?.Amount || 0;
      const wholesaleAmount = purchaseData.find(item => item.sptype === 'WHOLESALE')?.Amount || 0;
      const taxFreeAmount = purchaseData.find(item => item.sptype === 'TAX FREE')?.Amount || 0;
      const jobworkAmount = purchaseData.find(item => item.sptype === 'JOBWORK')?.Amount || 0;
      const branchTransferAmount = purchaseData.find(item => item.sptype === 'BRANCHTRANSFER')?.Amount || 0;

      // Only set the values if purchaseData is not empty
      setCurrentLocalAmount(localAmount);
      setCurrentOutsideAmount(outsideAmount);
      setCurrentWholesaleAmount(wholesaleAmount);
      setCurrentTaxFreeAmount(taxFreeAmount);
      setCurrentJobworkAmount(jobworkAmount);
      setCurrentBranchTransferAmount(branchTransferAmount);
    }
  }, [purchaseData]);
  // Only re-run this effect when purchaseData changes

  const purchaseTypes = [
    { type: 'LOCAL', amount: currentLocalAmount, color: '#4BC0C0' },
    { type: 'OUTSIDE', amount: currentOutsideAmount, color: '#FF9F40' },
    { type: 'WHOLESALE', amount: currentWholesaleAmount, color: '#FF6384' },
    { type: 'TAX FREE', amount: currentTaxFreeAmount, color: '#36A2EB' },
    { type: 'JOBWORK', amount: currentJobworkAmount, color: '#FFCE56' },
    { type: 'BRANCHTRANSFER', amount: currentBranchTransferAmount, color: '#9966FF' }
  ];

  // Filter out the items with zero amounts
  const filteredPurchaseTypes = purchaseTypes.filter(item => item.amount > 0);

  // Prepare the labels, data, and colors for the pie chart based on the filtered data
  const labelsForPurchase = filteredPurchaseTypes.map(item => item.type);
  const dataForPurchase = filteredPurchaseTypes.map(item => item.amount);
  const backgroundColorsForPurchase = filteredPurchaseTypes.map(item => item.color);

  // Prepare the Pie chart data for purchases
  const pieData = {
    labels: labelsForPurchase,
    datasets: [{
      data: dataForPurchase,
      backgroundColor: backgroundColorsForPurchase,
      hoverBackgroundColor: backgroundColorsForPurchase
    }]
  };

  // Table rows for purchases
  const purchaseTableRows = filteredPurchaseTypes.map(item => (
    <tr key={item.type}>
      <td>{item.type}</td>
      <td><strong className="amount">Rs. {item.amount}</strong></td>
    </tr>
  ));


  // Filter out sales types where the amount is 0
  const salesTypes = [
    { type: 'CASH', amount: currentTotalCash, color: '#FF6384' },
    { type: 'CREDIT', amount: currentTotalCredit, color: '#36A2EB' },
    { type: 'CARD', amount: currentCard, color: '#FFCE56' },
    { type: 'WALLET', amount: currentWallet, color: '#4BC0C0' },
    { type: 'CR-NOTE', amount: currentCreditNote, color: '#9966FF' }
  ];

  // Filter out the items with zero amounts
  const filteredSalesTypes = salesTypes.filter(item => item.amount > 0);

  // Prepare the labels, data, and colors for the pie chart based on the filtered data
  const labelsForSales = filteredSalesTypes.map(item => item.type);
  const dataForSales = filteredSalesTypes.map(item => item.amount);
  const backgroundColorsForSales = filteredSalesTypes.map(item => item.color);

  // Prepare the Pie chart data for sales
  const pieDataForSales = {
    labels: labelsForSales,
    datasets: [{
      data: dataForSales,
      backgroundColor: backgroundColorsForSales,
      hoverBackgroundColor: backgroundColorsForSales
    }]
  };

  // Table rows for sales
  const salesTableRows = filteredSalesTypes.map(item => (
    <tr key={item.type}>
      <td>{item.type}</td>
      <td><strong className="amount">Rs. {item.amount}</strong></td>
    </tr>
  ));

  // Define chart options for better display
  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom', // Position the legend at the bottom
        labels: {
          font: {
            size: 10, // Set the font size smaller
            family: 'Arial', // Specify a font family here
          },
          padding: 15, // Adjust padding between items
          boxWidth: 15, // Set the width of the box
          boxHeight: 15, // Set the height of the box
        },
      },
    },
  };

  // Bar chart data for receivables and payables
  const barData = {
    labels: ['Total Receivables', 'Total Payables'],
    datasets: [{
      data: [totalReceivables, totalPayables],
      backgroundColor: [
        'rgba(54, 162, 235, 0.6)', // Receivables
        'rgba(255, 99, 132, 0.6)'  // Payables
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)', // Receivables border
        'rgba(255, 99, 132, 1)'  // Payables border
      ],
      borderWidth: 2,
      borderRadius: 5 // Optional: Add rounded corners
    }]
  };

  const options = {
    scales: {
      y: {
        ticks: {
          stepSize: 50000, // Adjust the step size as needed
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          generateLabels: (chart) => {
            return [
              {
                text: 'Receivables',
                fillStyle: 'rgba(54, 162, 235, 0.6)',
              },
              {
                text: 'Payables',
                fillStyle: 'rgba(255, 99, 132, 0.6)',
              },
            ];
          },
          padding: 20,
          boxWidth: 14,
          boxHeight: 14,
        },
        onClick: () => { },
        onHover: () => { },
      },
    },
  };


  return (
    <div>
      <Sidebar />
      <div className="dashboard-container">
        <div className="info-container">
          <strong>{companyName}</strong>
          <div>Period: {fromDate} - {toDate}</div>
        </div>
        <div className="cards-container">
          <div className="card-row">
            <Card title="Today's Sales" content={
              <div className="pie-chart" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Pie data={pieDataForSales} options={chartOptions} />  {/* Use pieDataForSales here */}
                <div style={{ textAlign: 'center' }}>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesTableRows}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            } />


            <Card title="Today's Purchases" content={
              <div className="pie-chart">
                <Pie data={pieData} options={chartOptions} />
                <div>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      {purchaseTableRows}
                    </table>
                  </div>
                </div>
              </div>
            } />



            <Card title="Total Outstanding Receivables & Payables" content={
              <div className="bar-graph-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Bar data={barData} options={options} />
                <div style={{ textAlign: 'center', marginTop: '25px' }}>
                  <div className="table-container1">
                    <table className="amount-table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>RECEIVABLES</td>
                          <td><strong className="amount">Rs. {Math.floor(totalReceivables)}</strong></td>
                        </tr>
                        <tr>
                          <td>PAYABLES</td>
                          <td><strong className="amount">Rs. {Math.floor(totalPayables)}</strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            } />

          </div>
          <div className="card-row">
            <SalesChart />
            <PurchaseChart />
          </div>
          <div className="card-row">
            <PaymentChart />
            <ReceiptChart />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
