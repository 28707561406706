import React, { useState } from 'react';
import './css/LoginForm.css';
import logo from './css/images.png';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Corrected import
import { useAuth } from '../context/AuthContext';  // Import useAuth hook

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();  // Access login function from AuthContext

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError('Both fields are required');
      return;
    }

    setError(''); // Reset any previous error

    try {
      // Send POST request to the backend
      const response = await fetch('http://dsapp.shanti-pos.com:8080/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const responseData = await response.json();
        console.error('Login failed:', responseData.message);  // Log the error from backend
        setError(responseData.message || 'An error occurred while logging in.');
        return;
      }

      const responseData = await response.json();
     

      // Check if the necessary tokens are returned
      if (!responseData.accessToken || !responseData.refreshToken) {
        setError('Login failed: No access or refresh tokens found');
        return;
      }

      // Store tokens and other necessary data in localStorage
      localStorage.setItem('accessToken', responseData.accessToken);
      localStorage.setItem('refreshToken', responseData.refreshToken);
      localStorage.setItem('email', email);

      // Decode the token and extract relevant information (optional)
      const decodedToken = jwtDecode(responseData.accessToken);
  

      // Optionally, store userId from decoded token if it's different from responseData._id
      localStorage.setItem('userId', decodedToken.id || responseData.data.id);

      // Update authentication state
      login();

      // Redirect to a protected route or dashboard
      navigate("/ips");  // Redirect to the dashboard after successful login
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      setError('An unexpected error occurred while logging in');
    }
  };

  return (
    <div>
      <img src={logo} alt="DeviSoft Logo" className="logo" />
      <form className="login-form-container" onSubmit={handleSubmit}>
        <div>
          <label className="login-form-label" htmlFor="email">User Email:</label>
          <input
            className="login-form-input"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}  // Update value based on email state
            required
          />
        </div>
        <div>
          <label className="login-form-label" htmlFor="password">Password:</label>
          <input
            className="login-form-input"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}  // Update value based on password state
            required
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
        <button className="login-form-button" type="submit">Login</button>

        <div className="forgot-password">
          <Link to="/forgot-password" className="forgot-password-link">Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
