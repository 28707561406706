import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import logo from './css/images.png';
import Lottie from 'react-lottie';
import loader from './css/loader.json';

const StaticIPs = () => {
  const [staticIPs, setStaticIPs] = useState({
    mainStaticIP: '', // Static IP from SystemAdmin
    additionalStaticIPs: [], // Static IPs from AdditionalUser
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize navigate hook

  // Memoize handleIPClick to avoid unnecessary re-creations
  const handleIPClick = useCallback((companyName, userId) => {
    // Store the userId in localStorage
    localStorage.setItem('userId', userId);
    // Navigate to the /year-select page
    navigate('/year-select');
  }, [navigate]);

  // Fetch static IPs after component mounts
  useEffect(() => {
    const fetchStaticIPs = async () => {
      try {
        // Retrieve the access token from localStorage (assuming it was stored during login)
        const token = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId'); // Assuming userId is also stored in localStorage

        if (!token) {
          throw new Error('No access token found. Please log in.');
        }

        if (!userId) {
          throw new Error('No user ID found. Please log in.');
        }

        // Make a GET request to fetch the static IPs for the given userId
        const response = await axios.get(`http://dsapp.shanti-pos.com:8080/user/static-ips/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Set the static IPs in state based on the correct structure
        setStaticIPs({
          mainStaticIP: response.data.staticIP || '', // Static IP from SystemAdmin (fallback to empty string if not present)
          additionalStaticIPs: response.data.staticIPs || [], // Static IPs from AdditionalUser (fallback to empty array if not present)
        });

        setLoading(false);

        // Auto-navigate if there's only one additionalStaticIP
        if (response.data.staticIPs && response.data.staticIPs.length === 1) {
          handleIPClick(response.data.staticIPs[0].companyName, response.data.staticIPs[0].userId);
        }
      } catch (err) {
        // Handle 404 error by logging out the user
        if (err.response && err.response.status === 404) {
          // Clear the localStorage and redirect to the login page
          localStorage.removeItem('accessToken');
          localStorage.removeItem('userId');
          navigate('/'); // Redirect to login page
        } else {
          setError(err.message || 'Failed to fetch static IPs');
        }
        setLoading(false);
      }
    };

    fetchStaticIPs();
  }, [handleIPClick, navigate]); // Add navigate to dependency array

  if (loading) {
    return (
      <div className="loader-overlay">
        <div className="loader-container">
          <Lottie options={{ animationData: loader, loop: true, autoplay: true }} height={100} width={100} />
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="message">Error: {error}</div>;
  }

  return (
    <div className="year-select-container">
      <img src={logo} alt="DeviSoft Logo" className="logo" />

      <div>
        <table className="ip-table">
          <thead>
            <tr>
              <th>Company Name</th>
            </tr>
          </thead>
          <tbody>
            {staticIPs.additionalStaticIPs.length > 0 ? (
              staticIPs.additionalStaticIPs.map((ip, index) => (
                <tr key={index}>
                  <td 
                    onClick={() => handleIPClick(ip.companyName, ip.userId)} 
                    style={{
                      color: '#007bff',  // Link color
                      textDecoration: 'underline',  // Underline like a link
                      cursor: 'pointer'  // Pointer cursor to indicate it's clickable
                    }}
                    onMouseEnter={(e) => e.target.style.color = '#0056b3'} // Hover effect
                    onMouseLeave={(e) => e.target.style.color = '#007bff'} // Reset hover effect
                  >
                    {ip.companyName}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No additional static IPs available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StaticIPs;
