import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import '../AdminSystem/NewUser.css';
import Sidebar from './SideBar';
import '../AdminSystem/EditUser.css';

const AdditionalUser = () => {
  // Extract id from URL using useParams
  const { id } = useParams();
  const navigate = useNavigate(); // Initialize navigate

  const [formData, setFormData] = useState({
    additionalBranch: '',
    staticIP: '',
    defaultDB: '',
    dbUsername: '',
    dbPassword: '',
    port: '',
  });

  const [loading, setLoading] = useState(false); // To track loading state
  const [error, setError] = useState(''); // To track errors

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `http://dsapp.shanti-pos.com:8080/additionalUser/create/${id}`,
        formData
      );
      alert('Additional User created successfully!');
      // Redirect to the get-user page or any other page after success
      navigate('/get-user');
    } catch (error) {
      console.error('Error creating additional user:', error);
      setError('There was an error creating the user. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sys-admin-form-container-edit">
      <Sidebar />
      <h2>Create Additional User</h2>
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="sys-admin-form">
        {/* Additional Branch Field */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="additionalBranch">Additional Branch</label>
            <input
              type="text"
              id="additionalBranch"
              name="additionalBranch"
              value={formData.additionalBranch}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Static IP, DB, Username, Password, and Port Fields */}
        <div className="form-group">
          <div className="form-field">
            <label htmlFor="staticIP">Static IP</label>
            <input
              type="text"
              id="staticIP"
              name="staticIP"
              value={formData.staticIP}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="defaultDB">Default Database</label>
            <input
              type="text"
              id="defaultDB"
              name="defaultDB"
              value={formData.defaultDB}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbUsername">Database Username</label>
            <input
              type="text"
              id="dbUsername"
              name="dbUsername"
              value={formData.dbUsername}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="dbPassword">Database Password</label>
            <input
              type="password"
              id="dbPassword"
              name="dbPassword"
              value={formData.dbPassword}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-field">
            <label htmlFor="port">Port</label>
            <input
              type="number"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="button-container">
          <button type="submit" className="sys-admin-update-btn" disabled={loading}>
            Create User
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdditionalUser;
